import React from 'react';
import './App.css';

function App() {
  return (
    <div className="app-container">

      <h1>AntePost – Notes for Calendar Events</h1>
      <p>
        AntePost is your productivity tool for staying organized before and after important meetings and
        events. With AntePost, you can easily create and send yourself notes or reminders ahead of time, ensuring
        you're prepared with key discussion points. After the event, use AntePost to jot down summaries, reflections,
        or follow-up tasks, keeping your thoughts organized and accessible.
      </p>
      <ul className="features-list">
        <li>Schedule notes before or after calendar events to prepare or reflect.</li>
        <li>Reminders automatically synced with your device’s calendar.</li>
        <li>Intuitive and sleek design for a smooth user experience.</li>
        <li>Simple interface to ensure quick note-taking on the go.</li>
      </ul>
      <footer>
        Whether you're preparing for a critical meeting or reflecting on an important discussion, AntePost helps you
        capture key insights and stay ahead of your schedule.
      </footer>
    </div>
  );
}

export default App;
